import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaWallet, FaSearch, FaBell, FaUser, FaRobot } from 'react-icons/fa'; // Importing FaRobot for AI icon
import { useAuth } from '../AuthContext'; // Import useAuth hook
import '../App.css';

function Navbar() {
    const location = useLocation();
    const { currentUser } = useAuth(); // Access currentUser from useAuth

    // If the user is not authenticated, return null to not render the Navbar
    if (!currentUser) {
        return null;
    }

    // Open AI assistant link
    const openAiLink = () => {
        window.open('https://chatgpt.com/g/g-nqlp3fqAd-indicatorbot', '_blank', 'noopener,noreferrer');
    };

    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li className={location.pathname === '/api' ? 'active' : ''}>
                    <Link to="/api" className={`nav-button ${location.pathname === '/api' ? 'active' : ''}`}>
                        <FaWallet size={30} />
                        <span>API</span>
                    </Link>
                </li>
                <li className={location.pathname === '/telegram' ? 'active' : ''}>
                    <Link to="/telegram" className={`nav-button ${location.pathname === '/telegram' ? 'active' : ''}`}>
                        <FaBell size={30} />
                        <span>Notifications</span>
                    </Link>
                </li>
                <li className={location.pathname === '/linkspage' ? 'active' : ''}>
                    <Link to="/linkspage" className={`nav-button ${location.pathname === '/linkspage' ? 'active' : ''}`}>
                        <FaSearch size={30} />
                        <span>Browse</span>
                    </Link>
                </li>
                <li className={location.pathname === '/account' ? 'active' : ''}>
                    <Link to="/account" className={`nav-button ${location.pathname === '/account' ? 'active' : ''}`}>
                        <FaUser size={30} />
                        <span>Account</span>
                    </Link>
                </li>
                <li>
                    <button onClick={openAiLink} className="nav-button1 ">
                        <FaRobot size={30} />
                        <span>Ai </span>
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
