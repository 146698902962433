
import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA9rSrjS6SpnIF_AQoN-GppM-mRV4tY2bQ",
  authDomain: "dynamicmargintrading-3e531.firebaseapp.com",
  projectId: "dynamicmargintrading-3e531",
  storageBucket: "dynamicmargintrading-3e531.appspot.com",
  messagingSenderId: "916119255669",
  appId: "1:916119255669:web:72b81941fd31104510963c"
};

let app;

// Check if a Firebase instance already exists. If not, initialize one.
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const db = getFirestore(app);
const auth = getAuth(app);

// Setting persistence to SESSION, which will keep the user logged in only until the browser is closed
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // Persistence is now set to the session, or handle persistence as you need
  })
  .catch((error) => {
    console.error("Firebase persistence error:", error);
  });
  
export { auth, db };
