import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import '../App.css'; // Make sure you point to the correct path for CSS
import { FaCopy, FaSignOutAlt } from 'react-icons/fa';

function AccountPage() {
  const [urls, setUrls] = useState([]);
  const [userId, setUserId] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      setUserId(auth.currentUser.uid);
      setUrls([
        `https://bybit.tradingrobots.io/${auth.currentUser.uid}`,
        `https://bitget.tradingrobots.io/${auth.currentUser.uid}`,
        `https://okx.tradingrobots.io/${auth.currentUser.uid}`,
        `https://apollox.tradingrobots.io/${auth.currentUser.uid}`,
        `https://bybitinverse.tradingrobots.io/${auth.currentUser.uid}`
      ]);
    }
  }, [auth.currentUser]);

  const copyUrlToClipboard = async (urlToCopy) => {
    try {
      await navigator.clipboard.writeText(urlToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } catch (err) {
      console.error('Failed to copy URL: ', err);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Failed to logout: ', error);
    }
  };

  return (
    <div className="account-container">
      <div className="account-details">
        <div className="user-id">
          User ID: <span>{userId}</span>
          <button onClick={handleLogout} className="logout-button">
            <FaSignOutAlt size={16} /> 
          </button>
        </div>
        {urls.map((url, index) => (
          <div className="url-entry" key={index}>
            URL {index + 1}: <span className="url-display">{url}</span>
            <button className="copy-button" onClick={() => copyUrlToClipboard(url)}>
              <FaCopy size={16} /> Copy
            </button>
            {isCopied && <span className="copied-message">Copied!</span>}
          </div>
        ))}
      </div>
      <div className="security-alert">
        🔒 <strong>Security Alert!</strong> 🔒
        <p>Please ensure that you keep your unique URLs and userId strictly confidential.</p>
      </div>
    </div>
  );
}

export default AccountPage;
