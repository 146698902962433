import React, { useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

const links = [
  {
    category: 'Automated Strategies',
    icon: faChartLine, // Add the icon here

    items: [
      {
        title: 'DRAGONFLY',
        url: 'https://www.tradingview.com/script/AtI3Cb1d-DMT-369-DRAGRONFLY-STRAT/',
        imageUrl: 'dragonfly.png',
        youtubeUrl: 'https://youtu.be/oAoGn3-CJhk',
      },
      {
        title: 'BUTTERFLY',
        url: 'https://www.tradingview.com/script/4lYKaMXS-Dynamic-Butterfly-Strategy/',
        imageUrl: 'butterfly.png',
        youtubeUrl: 'https://youtu.be/KRR6a_RhPGI',
      },
    ],
  },
  // Add more categories and links here as needed
];

const LinksPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const handleOpenLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleOpenModal = (youtubeUrl) => {
    setModalUrl(youtubeUrl);
    setShowModal(true);
  };

  return (
    <div className="links-page">
      {links.map((linkCategory, categoryIndex) => (
        <div key={categoryIndex} className="link-category">
          <h2 className="category-title">
            <FontAwesomeIcon icon={linkCategory.icon} size="1x" /> {linkCategory.category}
          </h2>

          <div className="link-cards">
            {linkCategory.items.map((link, index) => (
              <div
                key={index}
                className="link-card"
                style={{ backgroundImage: `url(${link.imageUrl})` }}
              >
                <div className="link-card-overlay">
                  <h3>{link.title}</h3>
                  <div className="link-button-row">
                    <button
                      className="link-button"
                      onClick={() => handleOpenLink(link.url)}
                    >
                      View Strategy
                    </button>
                    <button
                      className="link-button"
                      onClick={() => handleOpenModal(link.youtubeUrl)}
                    >
                      Watch Video
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      {showModal && (
        <div className="modal">
          <div className="overlay" onClick={() => setShowModal(false)}></div>
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowModal(false)}>&times;</button>
            <div className="modal-content-body">
              <iframe
                width="100%"
                height="90%"
                src={modalUrl}
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinksPage;
