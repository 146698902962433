import React, { useState, useEffect, useCallback } from 'react';
import '../App.css';
import { collection, addDoc, getDocs, deleteDoc, doc, getDoc, setDoc, query, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import ClipLoader from 'react-spinners/ClipLoader';
import { FaTrash, FaInfoCircle } from 'react-icons/fa';
import AiButton from '../AiButton';

const Api = () => {
  const [bots, setBots] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPassphrase, setShowPassphrase] = useState(false);

  useEffect(() => {
    const currentUserId = auth.currentUser?.uid;
    if (currentUserId) {
      setUserId(currentUserId);
    }
  }, []);

  const fetchBots = useCallback(async () => {
    if (!userId) return;

    const automatonBybitCollection = collection(db, 'automaton-bybit');
    const wizardBybitCollection = collection(db, 'wizard-bybit');
    const toreedoPhemexCollection = collection(db, 'toreedo-phemex');
    const copytradeBybitCollection = collection(db, 'copytrade-bybit');
    const dexApolloxCollection = collection(db, 'DEX-APOLLOX');
    const bitgetCollection = collection(db, 'automaton-bitget');
    const okxCollection = collection(db, 'automaton-okx');

    const automatonBybitQuery = query(automatonBybitCollection, where('userId', '==', userId));
    const wizardBybitQuery = query(wizardBybitCollection, where('userId', '==', userId));
    const toreedoPhemexQuery = query(toreedoPhemexCollection, where('userId', '==', userId));
    const copytradeBybitQuery = query(copytradeBybitCollection, where('userId', '==', userId));
    const dexApolloxQuery = query(dexApolloxCollection, where('userId', '==', userId));
    const bitgetQuery = query(bitgetCollection, where('userId', '==', userId));
    const okxQuery = query(okxCollection, where('userId', '==', userId));

    const automatonBybitSnapshot = await getDocs(automatonBybitQuery);
    const wizardBybitSnapshot = await getDocs(wizardBybitQuery);
    const toreedoPhemexSnapshot = await getDocs(toreedoPhemexQuery);
    const copytradeBybitSnapshot = await getDocs(copytradeBybitQuery);
    const dexApolloxSnapshot = await getDocs(dexApolloxQuery);
    const bitgetSnapshot = await getDocs(bitgetQuery);
    const okxSnapshot = await getDocs(okxQuery);

    const automatonBybitBots = automatonBybitSnapshot.docs.map((doc) => ({
      id: doc.id,
      exchange: 'automaton-bybit',
      ...doc.data()
    }));

    const wizardBybitBots = wizardBybitSnapshot.docs.map((doc) => ({
      id: doc.id,
      exchange: 'wizard-bybit',
      ...doc.data()
    }));

    const toreedoPhemexBots = toreedoPhemexSnapshot.docs.map((doc) => ({
      id: doc.id,
      exchange: 'toreedo-phemex',
      ...doc.data()
    }));

    const dexApollox = dexApolloxSnapshot.docs.map((doc) => ({
      id: doc.id,
      exchange: 'DEX-APOLLOX',
      ...doc.data()
    }));

    const copytradeBybitBots = copytradeBybitSnapshot.docs.map((doc) => ({
      id: doc.id,
      exchange: 'copytrade-bybit',
      ...doc.data()
    }));

    const bitget = bitgetSnapshot.docs.map((doc) => ({
      id: doc.id,
      exchange: 'automaton-bitget',
      ...doc.data()
    }));

    const okx = okxSnapshot.docs.map((doc) => ({
      id: doc.id,
      exchange: 'automaton-okx',
      ...doc.data()
    }));

    const allBots = [...automatonBybitBots, ...wizardBybitBots, ...toreedoPhemexBots, ...copytradeBybitBots, ...dexApollox, ...bitget, ...okx];

    setBots(allBots);
  }, [userId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const exchange = event.target.exchange.value;

    const newBot = {
      userId,
      apiKey: event.target.apiKey.value,
      apiSecret: event.target.apiSecret.value,
      API_KEY: event.target.apiKey.value,
      SECRET_KEY: event.target.apiSecret.value,
      secret: event.target.apiSecret.value,
      botName: event.target.botName.value,
      enableTrading: false,
      webhook: true,
    };

    if (exchange === 'automaton-okx' || exchange === 'automaton-bitget') {
      newBot.passphrase = event.target.passphrase.value;
    }

    const docName = `${userId}-${newBot.botName}`;

    setLoading(true);

    const botDocRef = doc(db, exchange, docName);
    const existingBotSnapshot = await getDoc(botDocRef);

    if (existingBotSnapshot.exists()) {
      alert("A bot with this name already exists for your account.");
      setLoading(false);
      return;
    }

    try {
      await setDoc(botDocRef, newBot);

      const balancesSubcollection = collection(botDocRef, 'balances');
      await addDoc(balancesSubcollection, { initialized: true });
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    event.target.reset();
    setShowPassphrase(false);
    fetchBots();
    setLoading(false);
  };

  const handleExchangeChange = (event) => {
    if (event.target.value === 'automaton-okx' || event.target.value === 'automaton-bitget') {
      setShowPassphrase(true);
    } else {
      setShowPassphrase(false);
    }
  };

  useEffect(() => {
    fetchBots();
  }, [fetchBots]);

  const handleDeleteBot = async (botId, exchange) => {
    if (window.confirm('Are you sure you want to delete this bot?')) {
      const botDocRef = doc(db, exchange, botId);
      setLoading(true);

      try {
        const balancesSubcollectionRef = collection(botDocRef, 'balances');
        const balancesSnapshot = await getDocs(balancesSubcollectionRef);
        const deletePromises = balancesSnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePromises);

        await deleteDoc(botDocRef);
        fetchBots();
      } catch (e) {
        console.error('Error deleting document or its associated subcollection: ', e);
      }

      setLoading(false);
    }
  };

  const countUserBots = async () => {
    if (!userId) return 0;

    const automatonBybitCollection = collection(db, 'automaton-bybit');
    const wizardBybitCollection = collection(db, 'wizard-bybit');
    const toreedoPhemexCollection = collection(db, 'toreedo-phemex');
    const copytradeBybitCollection = collection(db, 'copytrade-bybit');
    const bitgetCollection = collection(db, 'automaton-bitget');
    const okxCollection = collection(db, 'automaton-okx');
    const dexApolloxCollection = collection(db, 'DEX-APOLLOX');

    const automatonBybitQuery = query(automatonBybitCollection, where('userId', '==', userId));
    const wizardBybitQuery = query(wizardBybitCollection, where('userId', '==', userId));
    const toreedoPhemexQuery = query(toreedoPhemexCollection, where('userId', '==', userId));
    const copytradeBybitQuery = query(copytradeBybitCollection, where('userId', '==', userId));
    const bitgetQuery = query(bitgetCollection, where('userId', '==', userId));
    const okxQuery = query(okxCollection, where('userId', '==', userId));
    const dexApolloxQuery = query(dexApolloxCollection, where('userId', '==', userId));

    const automatonBybitSnapshot = await getDocs(automatonBybitQuery);
    const wizardBybitSnapshot = await getDocs(wizardBybitQuery);
    const toreedoPhemexSnapshot = await getDocs(toreedoPhemexQuery);
    const copytradeBybitSnapshot = await getDocs(copytradeBybitQuery);
    const bitgetSnapshot = await getDocs(bitgetQuery);
    const okxSnapshot = await getDocs(okxQuery);
    const dexApolloxSnapshot = await getDocs(dexApolloxQuery);

    return dexApolloxSnapshot.size + okxSnapshot.size + bitgetSnapshot.size + automatonBybitSnapshot.size + wizardBybitSnapshot.size + toreedoPhemexSnapshot.size + copytradeBybitSnapshot.size;
  };

  return (
    <div className="loader">
      <div className="bot-page-background">
        <div className="form-container">
          <button className="info-button" onClick={() => setShowModal(true)}>
            <FaInfoCircle />
          </button>

          <form onSubmit={handleSubmit} autoComplete="off">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
            <div className="input-group">
              <select name="exchange" id="exchange" className="input-field" onChange={handleExchangeChange} required>
                <option value="" disabled>Select Exchange</option>
                <option value="automaton-bybit">Bybit</option>
                <option value="automaton-okx">OKX</option>
                <option value="automaton-bitget">Bitget</option>
                <option value="copytrade-bybit">Copytrade Bybit</option>
                <option value="DEX-APOLLOX">ApolloX</option>
              </select>
              <label className="floating-label"></label>
            </div>
            <div className="input-group">
              <input type="text" name="apiKey" id="apiKey" className="input-field" required />
              <label className="floating-label">API Key</label>
            </div>
            <div className="input-group">
              <input type="text" name="apiSecret" id="apiSecret" className="input-field" required />
              <label className="floating-label">API Secret</label>
            </div>
            {showPassphrase && (
              <div className="input-group">
                <input type="text" name="passphrase" id="passphrase" className="input-field" required />
                <label className="floating-label">Passphrase</label>
              </div>
            )}
            <div className="input-group">
              <input type="text" name="botName" id="botName" className="input-field" required />
              <label className="floating-label">Bot Name</label>
            </div>
            <div className="button-group">
              <button type="submit" className="button">CREATE</button>
            </div>
          </form>
          <div className="bots-list">
            {bots.map((bot) => (
              <div className="bot-card" key={bot.id}>
                <div className="bot-info">
                  <div className="bot-name">{bot.botName}</div>
                  <div className="exchange">{bot.exchange}</div>
                </div>
                <button className="delete-button" onClick={() => handleDeleteBot(bot.id, bot.exchange)}>
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="overlay" onClick={() => setShowModal(false)}></div>
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowModal(false)}>
              &times;
            </button>
            <div className="modal-content-body">
              <h2>API User Guide</h2>
              <p>
                Welcome to the API page. This is the central hub of our trading platform, the BotPage. This page allows you to manage, create, and delete bots associated with your account efficiently.
              </p>
              <p>
                <strong>How to Create a New Bot</strong><br />
                Navigate to the Bots tab.<br />
                Use the form to select the desired exchange from the dropdown menu. Options include:
                <ul>
                  <li>Bybit</li>
                  <li>Bitget</li>
                  <li>Okx</li>
                  <li>Apollox</li>
                  <li>Copytrade Bybit</li>
                </ul>
                Enter your API Key and API Secret in the designated fields.<br />
                Assign a unique name to your bot in the Bot Name field.<br />
                Click the CREATE button to add your bot to the list.
              </p>
              <p>
                <strong>Viewing Your Bots</strong><br />
                The Bots section provides a list showcasing each bot's unique name and the exchange it is associated with.
              </p>
              <p>
                <strong>Deleting a Bot</strong><br />
                Find the bot you want to delete from the list.<br />
                Click the trash icon next to the bot's name.<br />
                Confirm your decision to delete the bot in the popup window. This action is irreversible and will remove the bot along with all its associated settings and data.
              </p>
              <p>
                <strong>Tips for a Smooth Experience</strong><br />
                Unique Bot Names: Ensure that each bot has a unique name to prevent any conflicts.<br />
                API Credentials: To avoid issues, verify your API Key and Secret carefully when setting up a new bot.<br />
                Deletion is Permanent: Exercise caution when deleting a bot as this cannot be undone.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Api;
